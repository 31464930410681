
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import CapitalizeFirst from '@/modules/common/filters/CapitalizeFirst';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';
import EventsModel from '@/modules/events/models/events.model';
import EventGroup from '../../interfaces/event-group.enum';

@Component({
    components: {
        EventTypeIcon,
        Flag,
    },
    filters: {
        CapitalizeFirst,
    },
})
export default class EventsCard extends Vue {
    @Prop({ required: true, type: Object })
    private event!: EventsModel;

    @Prop({ required: false, type: Boolean })
    public isShort: boolean | undefined;

    get isMy() {
        return this.event.group === EventGroup.MAIN
            || this.event.group === EventGroup.CHAIN;
    }

    get isSuggested() {
        return this.event.group === EventGroup.SUGGESTED;
    }

    get isHoliday() {
        return this.event.group === EventGroup.HOLIDAY;
    }

    handleClick() {
        this.$emit('selectEvent', this.event);
    }
}
