var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'events-card': true,
        'events-card--hotel': _vm.isSuggested,
        'events-card--holiday': _vm.isHoliday,
        'events-card--approved': _vm.isMy,
    },on:{"click":_vm.handleClick}},[_c('div',{class:{
            'events-card__icon': true,
            'events-card__icon--holiday': _vm.isHoliday,
        }},[(_vm.isHoliday)?_c('Flag',{attrs:{"country-code":_vm.event.countryCode}}):_vm._e(),(_vm.isMy || _vm.isSuggested)?_c('EventTypeIcon',{attrs:{"type":_vm.event.type}}):_vm._e()],1),(_vm.isHoliday)?_c('div',{staticClass:"events-card__text"},[(!_vm.isShort)?_c('p',{staticClass:"events-card__country-name"},[_vm._v(" "+_vm._s(_vm._f("CapitalizeFirst")(_vm.event.country))+" ")]):_vm._e(),_c('p',{staticClass:"events-card__name"},[_vm._v(" "+_vm._s(_vm._f("CapitalizeFirst")(_vm.event.name))+" ")])]):_c('div',{staticClass:"events-card__text"},[_c('p',{staticClass:"events-card__country-name"},[_vm._v(" "+_vm._s(_vm._f("CapitalizeFirst")(_vm.event.name))+" ")]),(!_vm.isShort)?_c('p',{staticClass:"events-card__name"},[_vm._v(" "+_vm._s(_vm._f("CapitalizeFirst")(_vm.event.type))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }