
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import EventsDayList from '@/modules/events/components/events-day-list.vue';
import { Inject } from 'inversify-props';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import EventsModel from '../../models/events.model';
import EVENT_STATUS_SETTINGS from '../../constants/event-statuses.constant';

@Component({
    components: {
        ModalWrapper,
        LoaderWrapper,
        PopupEventsContainer,
        EventsDayList,
    },
})

export default class EventsDayPopup extends Vue {
    @Inject(EventsManagerServiceS)
    eventsManagerService!: EventsManagerService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({ required: true })
    private day!: number;

    get holidayEvents() {
        const { status } = this.eventsManagerService.settings;
        if (status !== EVENT_STATUS_SETTINGS.ALL) return [];

        return this.filterArray(this.monthEvents?.holiday || []);
    }

    get myEvents() {
        const { status, types } = this.eventsManagerService.settings;
        if (status === EVENT_STATUS_SETTINGS.SUGGESTED) return [];

        return this.monthEvents?.my.filter(event => types.includes(event.type!)) || [];
    }

    get suggestedEvents() {
        const { hotelId } = this.$route.params;
        if (!hotelId) return []; // NOTE suggested events should be shown only for hotel

        const { status, types } = this.eventsManagerService.settings;
        if (status === EVENT_STATUS_SETTINGS.APPROVED) return [];

        return this.monthEvents?.suggested.filter(event => types.includes(event.type!)) || [];
    }

    get chainEvents() {
        const { status, types } = this.eventsManagerService.settings;
        if (status === EVENT_STATUS_SETTINGS.SUGGESTED) return [];
        return this.monthEvents?.chain.filter(event => types.includes(event.type!)) || [];
    }

    get monthEvents() {
        const { day } = this;
        const { year, month } = this.documentFiltersService;
        const date = new Date(year, month, day);

        return this.eventsManagerService
            .getEventCollection(date);
    }

    private filterArray(arr: EventsModel[]) {
        const { countries } = this.eventsManagerService.storeState.settings;
        return arr.filter(event => countries.includes(event.country!));
    }

    closeModal() {
        const targetName = this.$route.name!.split('.');
        targetName.pop();

        this.$router.push({
            name: targetName.join('.'),
            params: {
                ...this.$route.params,
            },
        });
    }
}
