
import { Inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EventsModel from '@/modules/events/models/events.model';
import EventsCard from '@/modules/events/components/events-manager/events-card.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import EventsManagerService, { EventsManagerServiceS } from '../events-manager.service';

@Component({
    components: {
        EventsCard,
    },
})
export default class EventsDayList extends Vue {
    @Inject(UserServiceS)
    private userSerivce!: UserService;

    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Prop({
        required: true,
        type: Array as () => EventsModel[],
    })
    public holiday!: EventsModel[];

    @Prop({
        required: true,
        type: Array as () => EventsModel[],
    })
    public my!: EventsModel[];

    @Prop({
        required: true,
        type: Array as () => EventsModel[],
    })
    public suggested!: EventsModel[];

    @Prop({
        required: true,
        type: Array as () => EventsModel[],
    })
    public chain!: EventsModel[];

    get filteredHolidays() {
        const { countries } = this.eventsManagerService.settings;

        return this.holiday
            .filter(event => !event.country || countries.includes(event.country));
    }

    openEvent(event: EventsModel) {
        const { isCarUser } = this.userSerivce;

        const routeBranch = this.$route.name!
            .split('.')[0]
            .replace('-home', '');

        this.$router.push({
            name: isCarUser
                ? 'events-manager.view'
                : `${routeBranch}.events-manager.view`,
            params: {
                ...this.$route.params,
                eventId: String(event.id),
            },
        });
    }
}
